import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import Typography from '../../../components/atoms/Typography';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { get, has, isEmpty } from 'lodash';
import Title from '../../../components/atoms/Title';
import Link from '../../../components/atoms/Link';
import Box from '../../../components/molecules/Box';
import Picture from '../../../components/atoms/Picture';
import Container from '../../../components/molecules/Container';
import Grid from '../../../components/molecules/Grid';
import clsx from 'clsx';
import NoSsr from '@material-ui/core/NoSsr';
import 'slick-carousel/slick/slick.css';
import '../../../../styles/themes/slick-themes.css';
import useStyles from './style';
import Hidden from '@material-ui/core/Hidden';
import CarouselCard from '../../../components/molecules/CarouselCard';
import { settings } from './config';
import ButtonMUI from '../../../components/atoms/Button';
import { useTheme } from '@material-ui/core/styles';
import { pushAnaylyticsData } from '../../../../../src/utils/analytics';
import { analyticsData } from '../../../../../src/constants/Analytics/analytics-data';
import { COMPONENT_NAME } from './locator';
import { createDynamicLabels } from '../../../../utils/analytics';
import checkEmptyObject from '../../../utils/checkEmptyObject';
import { Helmet } from 'react-helmet';
import { parseToLDJson } from './parseToLDJson';
import { canUseDOM } from '../../../utils/canUseDOM';
import { useMediaQuery } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getMultiFilterResultStart } from '../../../containers/common/MultiFilteredLayout/redux/actions';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import DctSvgIcon from '../../atoms/Svg';

const SharedMultiCardContent = props => {
  const isFieldsEmpty = checkEmptyObject(props.fields);
  const {
    isCollageType1,
    carouselItems,
    title,
    description,
    cta,
    SEOEnglishCTA,
    segmentName,
    collageType1Items,
    logoImage,
    logoLink = { value: { href: '' } },
    language,
    ctabuttonText,
    pageName,
    componentName,
    supergraphicOnHover,
    supergraphicOnSelect,
    multifilterCategoryBtns = [],
    ctaLink
  } = get(props, 'fields', '');
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width:${1023}px)`);
  const { hasFavouriteBasket, multiFilteredSelectedItems, selectedFilterCategory = [], clearMultiFilters } = props;
  const isPillarCarousel = componentName === 'PillarsCarousel'
  const isEventsCarousel = componentName === 'EventsCarousel'
  const isMultiFilteredLayout = componentName === 'MultiFilteredLayout'
  const characterLimit = isEventsCarousel ? 15 : ''
  const [selectedFilterBtn, setSelectedFilterBtn] = useState([])
  const [showCategoriesBtns, setShowCategoriesBtns] = useState(true);
  const [multiFilterID, setMultiFilterID] = useState(null)
  const { isFetching } = useSelector(state => state.MultiFilterLayoutReducer);
  // Component has support for video but CMS does not have video field

  // Add videoDesktop & videoMobile in variable collageType1Items.fields [Temporarily]
  // if (carouselItems && carouselItems[0] && carouselItems[0].fields) {
  //   carouselItems[0].fields.videoDesktop = get(carouselItems[0], 'fields.videoDesktop', { value: { url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4' } });
  //   carouselItems[0].fields.videoMobile = get(carouselItems[0], 'fields.videoMobile', { value: { url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4' } });
  // }
  if (carouselItems && carouselItems.length <= 3) {
    settings.arrows = false;
  } else {
    settings.arrows = true;
  }


  // TODO:  Need to take value of button from cta only. Discussed with Sitecore person.
  const btnTextDescription = get(description, 'value', '');
  const btnTextCta = get(cta, 'value.text', '');
  let btnText = btnTextCta ?? btnTextDescription;

  if (isEventsCarousel && btnText.length > characterLimit) {
    const truncateText = btnText.substring(0, characterLimit);
    btnText = truncateText + '...'
  }

  const { carousalType } = get(props, 'params', '');
  const colorTheme = get(props, 'params.theme', '');
  const isCarouselType1 = carousalType === 'Type1';
  const classes = useStyles(props);
  const theme = useTheme();
  const isRTL = theme && theme.direction === 'rtl';
  const [activeCardIndex, setActiveCardIndex] = useState(0)
  const isType3WithDescription = carousalType === 'Type3WithDescription'

  // Reverse carouselItems sequence if RTL is true
  if (carouselItems && carouselItems.length > 0 && isRTL && !isPillarCarousel && !isMultiFilteredLayout) {
    carouselItems.reverse();
  }

  const sliderRef = useRef(null);
  const mainElementRef = useRef(null);

  let slickSettings = { ...settings };
  // let slickSettings = { ...settings, ...(showFilteredScreen && isMultiFilteredLayout && { slidesToShow: 4 }) };
  let aspectRatio = {};
  let supergraphicImage = '';
  if (isCarouselType1) {
    supergraphicImage = get(props, 'fields.supergraphicImage.value.src', '')
    aspectRatio = { mobile: '4x5', desktop: '4x5' };
    slickSettings = {
      ...settings,
      initialSlide: 0,
      isRTL: isRTL,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            ...settings.responsive[0].settings,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 1023,
          settings: {
            ...settings.responsive[1].settings,
          },
        },
      ],
    };
  } else if (carousalType === 'Type3') {
    const initialSlideVal = 0;
    aspectRatio = { mobile: '2x1', desktop: '2x1' };
    slickSettings = {
      ...settings,
      rtl: isRTL,
      initialSlide: initialSlideVal,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: true,
            centerPadding: '24px',
            arrows: false,
            useTransform: false,
            useCSS: false,
            touchMove: false,
          },
        },
      ],
    };
  }
  else if (isPillarCarousel) {
    slickSettings = {
      ...settings,
      initialSlide: isRTL ? 2 : 0,
      rtl: isRTL,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: true,
            centerPadding: '29px',
            arrows: false,
            useTransform: false,
            useCSS: false,
            touchMove: false,
            rtl: false,
          },
        },
      ],
    };
  }
  else if (isMultiFilteredLayout) {
    slickSettings = {
      ...settings,
      rtl: isRTL,
      slidesToShow: 3,
      initialSlide: isRTL ? 3 : 0,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: true,
            centerPadding: '30px',
            arrows: false,
            useTransform: false,
            useCSS: false,
            touchMove: false,
            rtl: false,
          },
        },
      ],
    };
  }


  useEffect(() => {
    if (isMultiFilteredLayout && Array.isArray(carouselItems) && carouselItems.length > 3) {
      if (sliderRef.current) {
        isRTL ? sliderRef.current.slickGoTo(carouselItems.length - 3) : sliderRef.current.slickGoTo(0)
      }
    }
  }, [carouselItems])

  const getRequestData = (offset = 0, limit = 10, filters = []) => {
    return {
      'searchTerm': 'multifilter',
      'language': isRTL ? 'ar' : 'en',
      'facetFields': [
      ],
      'ignoreSearchTerm': true,
      'limit': limit,
      'offset': offset,
      filters,
    }
  }

  const getRandomNumber = () => {
    setMultiFilterID(Math.random())
  }

  const formatMultifilterCategory = (multifilterCategoryBtns) => {
    return multifilterCategoryBtns.flatMap(item => item.filters.map(filter => ({ key: filter.value, value: filter.key })));
  }

  const handleGetAllFilters = () => {
    setSelectedFilterBtn([])
    let allFilters = formatMultifilterCategory(multifilterCategoryBtns)
    dispatch(getMultiFilterResultStart(getRequestData(0, 11, allFilters)));
    if (isMobile) {
      getRandomNumber()
    }
  }

  const handleFilterBtn = (value) => {
    let updateItems = [...selectedFilterBtn]
    const found = selectedFilterBtn.find(x => x.id === value.id)
    if (found) updateItems = updateItems.filter(x => x !== found)
    else updateItems.push(value)
    let mergedFilters = formatMultifilterCategory(updateItems);
    if (mergedFilters.length === 0) {
      mergedFilters = multifilterCategoryBtns.flatMap(item => item.filters.map(filter => ({ key: filter.value, value: filter.key })));
    }
    dispatch(getMultiFilterResultStart(getRequestData(0, 11, updateItems.length === 0 ? formatMultifilterCategory(multifilterCategoryBtns) : mergedFilters)));
    setSelectedFilterBtn(updateItems)
    if (isMobile) {
      getRandomNumber()
    }
  }

  const initSlider = () => {
    if (isRTL) {
      const carouselLen = carouselItems?.length ? carouselItems.length : 0

      // if carousel length is > 3 & settigns have arrows enabled
      if (carouselLen > 3 && settings.arrows) {
        // disable main element
        mainElementRef.current.style.opacity = 0;
        mainElementRef.current.style.pointerEvents = 'none';
      }

      setTimeout(() => {
        sliderReady();
      }, 600);
    }
  };

  const sliderReady = () => {
    if (sliderRef && sliderRef.current) {

      sliderRef.current.slickGoTo(0);
      setTimeout(() => {
        slide_fix();
      }, 600);
    } else {
      setTimeout(() => {
        sliderReady();
      }, 600);
    }
  };

  const slide_fix = () => {
    // click slide slick-next button till its disabled, after every 500ms
    if (mainElementRef && mainElementRef.current) {
      const slickNextBtn = mainElementRef.current.querySelector('.slick-next');
      // if arrows dont exists then show main element
      if (!slickNextBtn) {
        mainElementRef.current.style.opacity = 1;
        mainElementRef.current.style.pointerEvents = 'auto';
        return
      }
      if (slickNextBtn && slickNextBtn.classList.contains('slick-disabled')) {
        // enable main element
        mainElementRef.current.style.opacity = 1;
        mainElementRef.current.style.pointerEvents = 'auto';
        return
      }
      if (slickNextBtn && !slickNextBtn.classList.contains('slick-disabled')) {
        slickNextBtn.querySelector('button').click();
        setTimeout(() => {
          slide_fix();
        }, 600);
      } else {
        setTimeout(() => {
          slide_fix();
        }, 600);
      }
    }
  }

  const addAnalyticsInfo = () => {
    let obj;
    if (isMultiFilteredLayout) {
      obj = {
        ...analyticsData.pageBodyInteraction.multiFilterBtnClick,
        ...{ label: createDynamicLabels([ctabuttonText?.value, componentName]) },
      };
    }
    else {
      obj = {
        ...analyticsData.pageBodyInteraction.arrowClick,
        ...{
          label: get(SEOEnglishCTA, 'value', ''),
        },
      };
    }
    pushAnaylyticsData(obj);
  };

  const addSwipeAnalyticsInfo = () => {
    const obj = {
      ...analyticsData.pageBodyInteraction.swipeArrowClick,
      ...{
        label: createDynamicLabels([
          pageName,
          componentName,
          `${title && title.value}`,
        ]),
      },
    };
    pushAnaylyticsData(obj);
  };

  const addTileAnalyticsInfo = (value) => {
    let tileValue = value?.title
    let sectionValue = title.value
    let obj;
    const found = selectedFilterBtn.find(x => x.id === value.id)
    if (!found) {
      obj = {
        ...analyticsData.pageBodyInteraction.tileClick,
        ...{
          label: createDynamicLabels([
            pageName,
            componentName,
            sectionValue && sectionValue
              ? `${sectionValue}-${tileValue}`
              : tileValue,
          ]),
        },
      };
      pushAnaylyticsData(obj);
    }
  };

  const addLogoAnalytics = () => {
    pushAnaylyticsData(
      get(analyticsData, 'pageBodyInteraction.dctLogoClick', '')
    );
  };

  const handleScroll = () => {
    let leftThreshold = -(window.innerWidth / 2);
    let rightThreshold = window.innerWidth / 2
    const cards = document.querySelectorAll(isPillarCarousel ? '[id^="pillarCard-"]' : isMultiFilteredLayout ? '[id^="filterCard-"]' : '');
    const activeCard = cards && Array.from(cards).filter((card) => {
      const cardSpecifications = card.getBoundingClientRect();
      if (!isRTL && cardSpecifications.left > leftThreshold && cardSpecifications.right > rightThreshold) {
        return true;
      }

      else if (isRTL && cardSpecifications.left < rightThreshold && cardSpecifications.right > leftThreshold &&
        cardSpecifications.right > 100) {
        return true;
      }
      else {
        return false;
      }
    }).map(card => parseInt(card.id.split('-')[1]));
    setActiveCardIndex(activeCard[0])
  }


  const multifilterCarouselProps = {
    ...(isMultiFilteredLayout && isMobile && {
      key: multiFilterID
    }),
    ...(isMultiFilteredLayout && isMobile && multiFilterID && {
      onInit: () => { }
    }),
  };

  const getHeadingContent = () => {
    return (
      <Grid>
        <div
          className={clsx(
            isCarouselType1 ? classes.description : clsx(classes.descriptionType3, 'alignment-type3description'),
            (isCollageType1 || isEventsCarousel) && classes.headingSection,
            isPillarCarousel ? 'isPillar-height' : '',
            'collage-description'
          )}
        >
          {segmentName && (
            <Title text={segmentName} className="segment-title" />
          )}
          {(!isPillarCarousel && !isMultiFilteredLayout) && title && (
            <Typography variant={isType3WithDescription ? 'h53Bold' : 'h2'} component="h2" className={`${classes.secheading} ${carousalType === 'Type3' && classes.headingSectionPeacockBlueColoTest} ${isType3WithDescription ? 'type3DescriptionPaddingAlign' : ''} sec-heading ${isEventsCarousel ? 'istruncated' : ''}`}>
              <Text field={title} />
            </Typography>
          )}
          {(isPillarCarousel || isMultiFilteredLayout) && (
            <>
              {title && (
                <Typography variant="h2" component="h2" className={`${classes.secheading} ${carousalType === 'Type3' ? classes.headingSectionPeacockBlueColoTest : ''} sec-heading mainPillarHead`}>
                  <Text field={title} />
                </Typography>
              )}
              {description && description.value && (
                <Typography variant="body1a" component="p" className={`${classes.secheading} ${carousalType === 'Type3' ? '' : ''} mainPillarPara`}>
                  <Text field={description} />
                </Typography>
              )}
            </>
          )}
          {isPillarCarousel && ctaLink && ctaLink.value && !isEmpty(ctaLink.value.href) && (
            <ButtonMUI
              className={`${classes.secheading} pillarCarouselBtn`}
              onClick={() => { }}
              component="div"
              buttonType="primary"
              field={ctaLink}
              srText={get(ctaLink, 'value.text', '')}
              hasBorder={false}
            />
          )}
          {!isMultiFilteredLayout &&
            <Hidden mdDown implementation="css">
              {btnText && cta && cta.value && (cta.value.href || btnText) && (
                <ButtonMUI
                  onClick={addAnalyticsInfo}
                  component="span"
                  field={cta}
                  className={classes.bannerCTA}
                  buttonType={isCarouselType1 ? 'whitePillContrast' : 'primary'}
                  size={'small'}
                  hasBorder={false}
                >
                  {btnText}
                </ButtonMUI>
              )}
            </Hidden>
          }
        </div>
      </Grid>
    );
  };

  const collageType1SchemaJSON = collageType1Items && parseToLDJson(collageType1Items);
  const carouselItemsSchemaJson = carouselItems && parseToLDJson(carouselItems);

  const truncateButtonTitle = (title, lim = 16, rtlLim = 12) => {
    let truncateTitle = title.toUpperCase();
    const charactersLimit = isRTL ? rtlLim : lim
    if (truncateTitle.length > charactersLimit) {
      truncateTitle = title.substring(0, charactersLimit);
      truncateTitle = truncateTitle + '...'
    }
    return truncateTitle
  }

  return (

    isFieldsEmpty ? <></> :
      <Box
        component="div"
        className={clsx(
          classes.root,
          isPillarCarousel ? 'PillarCard Type3WithDescription' : carousalType,
          isMultiFilteredLayout ? 'MultiFilteredCard  Type3WithDescription' : carousalType,
          isEventsCarousel && 'isEventCarousel',
          (isCollageType1 && !isEventsCarousel) && 'isCollage',
          isRTL && classes.rtlClass,
          isRTL && 'is-rtl',
        )}
        aria-label={`${title?.value} ${getDictionaryText('Carousel')}`}
        data-locator={COMPONENT_NAME}
        ref={mainElementRef}
      >
        {isCarouselType1 && supergraphicImage && (
          <div className={'background-supergraphics'}></div>
        )}

        <Container className={(isCollageType1 || isEventsCarousel) ? classes.collageType1 : ''}>
          {isCollageType1 || isEventsCarousel ? (
            <Box
              className={`${classes.headingContent} ${(isCollageType1 || isEventsCarousel) &&
                classes.headingBackground}`}
            >
              {getHeadingContent()}
              <Box className={classes.collageType1Logo}>
                <Link field={logoLink}>
                  <Picture
                    onClick={addLogoAnalytics}
                    media={{
                      image: logoImage,
                      mobileImage: logoImage,
                      renditionNeeded: false,
                      disableLazyLoad: true,
                      fallbackBackgroundNeeded: false,
                    }}
                    isParalax={false}
                    alternateAltText={title && title.value}
                  />
                </Link>
              </Box>
            </Box>
          ) : (
            getHeadingContent()
          )}
        </Container>
        {
          isMultiFilteredLayout && (
            <>
              {isMobile && (
                <Box className="multi-filter-category-btn-container">
                  <ButtonMUI
                    type={'button'}
                    className={`multi-filter-category-btn ${showCategoriesBtns &&
                      'multi-filter-category-btn-active'}`}
                    onClick={() => {
                      setShowCategoriesBtns(prev => !prev);
                    }}
                  >
                    <DctSvgIcon name="FilterIcon" />
                    {selectedFilterBtn.length > 0 ? selectedFilterBtn.map((btn, idx) => {
                      if (selectedFilterBtn.length === 1) return truncateButtonTitle(btn.title, 30, 24)
                      else if (selectedFilterBtn.length === 2) {
                        return idx === 0 ? `${truncateButtonTitle(btn.title)}, ` : truncateButtonTitle(btn.title);
                      }
                      else return idx < 2 ? `${truncateButtonTitle(btn.title)}, ` : '';
                    })
                      : `${getDictionaryText('multifilter_all_text')} ${getDictionaryText('multifilter_categories_text')}`
                    }
                    {selectedFilterBtn.length > 2 ? `+${selectedFilterBtn.length - 2}` : ''}
                  </ButtonMUI>
                </Box>
              )}
              {showCategoriesBtns && (
                <Box className={`multi-filter-btns-wrapper mulit-filter-btn-active`}>
                  <Box className={'multi-filter-btns-alignment'}>
                    <ButtonMUI
                      disabled={isFetching}
                      hideEndIcon={true}
                      buttonType="form"
                      size="small"
                      hasBorder={false}
                      className={`multi-filter-btn ${selectedFilterBtn.length < 1 ? 'multi-filter-btn-variant' : ''}`}
                      onClick={() => {
                        handleGetAllFilters()
                        // addTileAnalyticsInfo(val)
                      }}
                    >
                      <Typography variant="labelBold" component="span" color="inherit">
                        <Text field={{ value: getDictionaryText('multifilter_all_text') }} />
                      </Typography>
                    </ButtonMUI>

                    {
                      Array.isArray(multifilterCategoryBtns) && multifilterCategoryBtns.map((val, i) => {
                        const { id, title } = val
                        let truncateTitle = truncateButtonTitle(title);
                        const hasFound = selectedFilterBtn.some(item => item.id === id)
                        return (
                          <ButtonMUI
                            disabled={isFetching}
                            key={i}
                            hideEndIcon={true}
                            buttonType="form"
                            size="small"
                            hasBorder={false}
                            className={`multi-filter-btn ${hasFound ? 'multi-filter-btn-variant' : ''}`}
                            onClick={() => {
                              handleFilterBtn(val)
                              addTileAnalyticsInfo(val)
                            }}
                          >
                            <Typography variant="labelBold" component="span" color="inherit">
                              <Text field={{ value: truncateTitle }} />
                            </Typography>
                          </ButtonMUI>
                        )
                      })
                    }
                  </Box>
                </Box>
              )}
            </>
          )
        }

        {isCollageType1 || isEventsCarousel ? (
          <Box component="div" className={`${classes.collageItems} ${isCollageType1 && !isEventsCarousel ? 'collageType1Alignment' : ''}`}>
            <Helmet>
              <script type="application/ld+json">{`${collageType1SchemaJSON}`}</script>
            </Helmet>
            <Slider
              afterChange={() => addSwipeAnalyticsInfo()}
              {...slickSettings}
              onInit={initSlider}
              ref={sliderRef}
            >
              {collageType1Items &&
                collageType1Items.map(item => (
                  <div key={item}>
                    <CarouselCard
                      sectionTitle={title}
                      title={get(item, 'fields.title', '')}
                      desktopImage={get(item, 'fields.desktopImage', '')}
                      mobileImage={get(item, 'fields.mobileImage', '')}
                      ctaLink={get(item, 'fields.attributeLabelCTA', [])}
                      imageCtaUrl={get(item, 'fields.primaryCTA', '')}
                      startDate={get(item, 'fields.startDate', '')}
                      locationTitle={get(item, 'fields.locationTitle.value', '')}
                      region={get(item, 'fields.region.value', '')}
                      endDate={get(item, 'fields.endDate', '')}
                      openTime={get(item, 'fields.openTime', '')}
                      closeTime={get(item, 'fields.closeTime', '')}
                      videoDesktop={get(item, 'fields.videoDesktop', '')}
                      videoMobile={get(item, 'fields.videoMobile', '')}
                      umbrellaEventCount={get(
                        item,
                        'fields.umbrellaEventCount',
                        ''
                      )}
                      eventType={get(item, 'fields.eventType', '')}
                      cardType={'event'}
                      language={language}
                      pageName={pageName}
                      urlSlug={get(item, 'fields.urlSlug', '')}
                      itemUniqueKey={get(item, 'fields.itemUniqueKey', '')}
                      showFavoriteIcon={hasFavouriteBasket}
                      lazyLoading={true}
                      componentName={componentName}
                      eventCategories={get(item, 'fields.eventCategories', [])}
                    />
                  </div>
                ))}
            </Slider>
          </Box>
        ) :
          (isPillarCarousel || isMultiFilteredLayout) ?
            (
              carouselItems && (
                <>
                  <Box component="div" onScroll={handleScroll} id='test' >
                    <NoSsr>
                      <Helmet>
                        <script type="application/ld+json">{`${carouselItemsSchemaJson}`}</script>
                      </Helmet>
                      <Slider
                        onInit={initSlider}
                        afterChange={() => {
                          addSwipeAnalyticsInfo()
                        }}
                        {...slickSettings}
                        {...multifilterCarouselProps}
                        ref={sliderRef}
                        className={clsx(isMultiFilteredLayout ? 'multiFilterPillarCard' : '')}
                      >
                        {carouselItems.map((item, index) => {
                          const itemLink = get(item, 'fields.link', '');
                          let cardUrl;
                          if (typeof itemLink === 'object' && itemLink !== null) {
                            // When itemLink is an object
                            cardUrl = itemLink;
                          } else {
                            // When itemLink is not an object
                            cardUrl = {
                              value: {
                                href: itemLink,
                              },
                            };
                          }

                          const imageFromGallery = get(item, 'fields.gallery', '');
                          return (
                            <div key={item} id={isPillarCarousel ? `pillarCard-${index}` : isMultiFilteredLayout ? `filterCard-${index}` : ''}>
                              <CarouselCard
                                sectionTitle={title}
                                cardType={(isPillarCarousel || isMultiFilteredLayout) ? 'pillarCarousel' : ''}
                                hoverColour={get(item, 'fields.hoverColour', '')}
                                title={
                                  has(item, 'fields.title')
                                    ? get(item, 'fields.title', '')
                                    : get(item, 'fields.name', '')
                                }
                                supergraphicColor={get(item, 'fields.supergraphicColor.value', '')}
                                description={get(item, 'fields.description.value', '')}
                                desktopImage={
                                  imageFromGallery.length > 0
                                    ? get(item, 'fields.gallery[0].fields.image', '')
                                    : get(item, 'fields.image', '')
                                }
                                mobileImage={
                                  imageFromGallery.length > 0
                                    ? get(
                                      item,
                                      'fields.gallery[0].fields.mobileImage',
                                      ''
                                    )
                                    : get(item, 'fields.mobileImage', '')
                                }
                                aspectRatio={aspectRatio}
                                ctaLink={
                                  carousalType === 'Type3WithDescription' ?
                                    get(item, 'fields.primaryCTA.value', '')
                                    :
                                    has(item, 'fields.attributeLabelCTA')
                                      ? get(item, 'fields.attributeLabelCTA.value.text', '')
                                      : get(
                                        item,
                                        'fields.suitableFor[0].fields.taxonomyValue.value',
                                        ''
                                      )
                                }
                                imageCtaUrl={
                                  carousalType === 'Type3WithDescription' ? '' :
                                    has(item, 'fields.primaryCTA')
                                      ? get(item, 'fields.primaryCTA', '')
                                      : cardUrl
                                }
                                videoUrl={get(item, 'fields.video.value.href', '')}
                                videoDesktop={get(item, 'fields.videoDesktop', '')}
                                videoMobile={get(item, 'fields.videoMobile', '')}
                                pageName={pageName}
                                componentName={componentName}
                                carousalType={carousalType}
                                forcedRatio={'2x1'}
                                itemUniqueKey={get(item, 'id', '')}
                                showFavoriteIcon={hasFavouriteBasket}
                                locationTitleTop={get(item, 'fields.location.value', '')}
                                colorTheme={colorTheme}
                                isParalax={!isCarouselType1}
                                activeCardIndex={activeCardIndex}
                                index={index}
                                itemId={get(item, 'id', '')}
                                itemData={item}
                                multiFilteredSelectedItems={multiFilteredSelectedItems}
                                supergraphicOnHover={supergraphicOnHover}
                                supergraphicOnSelect={supergraphicOnSelect}
                                tag={get(item, 'fields.tag', null)}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </NoSsr>
                  </Box>
                </>
              )
            )

            : (
              (carouselItems && !isMultiFilteredLayout) ? (
                <NoSsr>
                  <Helmet>
                    <script type="application/ld+json">{`${carouselItemsSchemaJson}`}</script>
                  </Helmet>
                  <Slider
                    afterChange={() => {
                      addSwipeAnalyticsInfo()
                    }}
                    {...slickSettings}
                    onInit={initSlider}
                    ref={sliderRef}
                  >
                    {carouselItems.map(item => {
                      const itemLink = get(item, 'fields.link', '');
                      let cardUrl;
                      if (typeof itemLink === 'object' && itemLink !== null) {
                        // When itemLink is an object
                        cardUrl = itemLink;
                      } else {
                        // When itemLink is not an object
                        cardUrl = {
                          value: {
                            href: itemLink,
                          },
                        };
                      }

                      const imageFromGallery = get(item, 'fields.gallery', '');
                      return (
                        <div key={item}>

                          <CarouselCard
                            sectionTitle={title}
                            cardType={(isPillarCarousel || isMultiFilteredLayout) ? 'pillarCarousel' : ''}
                            hoverColour={get(item, 'fields.hoverColour', '')}
                            title={
                              has(item, 'fields.title')
                                ? get(item, 'fields.title', '')
                                : get(item, 'fields.name', '')
                            }
                            description={get(item, 'fields.description.value', '')}
                            desktopImage={
                              imageFromGallery.length > 0
                                ? get(item, 'fields.gallery[0].fields.image', '')
                                : get(item, 'fields.image', '')
                            }
                            mobileImage={
                              imageFromGallery.length > 0
                                ? get(
                                  item,
                                  'fields.gallery[0].fields.mobileImage',
                                  ''
                                )
                                : get(item, 'fields.mobileImage', '')
                            }
                            aspectRatio={aspectRatio}
                            ctaLink={
                              carousalType === 'Type3WithDescription' ?
                                get(item, 'fields.primaryCTA.value', '')
                                :
                                has(item, 'fields.attributeLabelCTA')
                                  ? get(item, 'fields.attributeLabelCTA.value.text', '')
                                  : get(
                                    item,
                                    'fields.suitableFor[0].fields.taxonomyValue.value',
                                    ''
                                  )
                            }
                            imageCtaUrl={
                              carousalType === 'Type3WithDescription' ? '' :
                                has(item, 'fields.primaryCTA')
                                  ? get(item, 'fields.primaryCTA', '')
                                  : cardUrl
                            }
                            videoUrl={get(item, 'fields.video.value.href', '')}
                            videoDesktop={get(item, 'fields.videoDesktop', '')}
                            videoMobile={get(item, 'fields.videoMobile', '')}
                            pageName={pageName}
                            componentName={componentName}
                            carousalType={carousalType}
                            forcedRatio={'2x1'}
                            itemUniqueKey={get(item, 'id', '')}
                            showFavoriteIcon={hasFavouriteBasket}
                            locationTitleTop={get(item, 'fields.location.value', '')}
                            colorTheme={colorTheme}
                            isParalax={!isCarouselType1}
                            itemId={get(item, 'id', '')}
                            itemData={item}
                            multiFilteredSelectedItems={multiFilteredSelectedItems}
                            supergraphicOnHover={supergraphicOnHover}
                            supergraphicOnSelect={supergraphicOnSelect}
                          />
                        </div>
                      );
                    })}
                  </Slider>
                </NoSsr>
              )
                : <></>
            )}
        {
          !isMultiFilteredLayout &&
          <Hidden lgUp implementation="css">
            {btnText && cta && cta.value && (cta.value.href || btnText) && (
              <Box
                component="div"
                px={3}
                className={(isCollageType1 || isEventsCarousel) && `${classes.discoverMore} ${isCollageType1 && !isEventsCarousel ? 'discoverMoreAlignment' : ''} linkBtn`}
              >
                <ButtonMUI
                  onClick={addAnalyticsInfo}
                  component="span"
                  field={cta}
                  className={classes.bannerCTA}
                  buttonType={isCarouselType1 ? 'whitePillContrast' : 'primary'}
                  size={'small'}
                  hasBorder={false}
                >
                  {btnText}
                </ButtonMUI>
              </Box>
            )}
          </Hidden>
        }
      </Box>
  );
};

export default SharedMultiCardContent;
