import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../../styles/themes/vad/constants';
import { borderRadius } from '../../../../../styles/themes/vad/borderRadius';

const useEventStyles = makeStyles(theme => ({
  eventCarouselContent: {
    color: '#FFFF',
    background: 'transparent',
    paddingTop: '32px',
    zIndex: 5,
    borderColor: theme.mixins.Grey(),
    ...theme.mixins.paddingLeft(theme.spacing(1)),
    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.paddingLeft(0),
      borderBottom: 'none',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '16px',
    },

    '& .customLink': {
      '&::after': {
        width: '100%',
        height: '100%',
        position: 'absolute',
        content: '""',
        top: '50%',
        left: '50%',
        transform: 'translate(-50% , -50%)'
      },
      '&:hover': {
        backgroundColor: theme.mixins.White(),
        '& h3': {
          color: theme.palette.common.SeaPort,
        },
        '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
          transform: 'scale3d(1.1, 1.1, 1.1)',
        },
        '& .icon img': {
          transform: 'none',
        },
      },
    },
    '& .eventName, & .eventLocation': {
      ...theme.palette.common.ellipsis(1),
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: 'normal',
      },
    },

    '& .eventLocation': {
      fontWeight: 400,
    },


    '& .eventTitle': {
      marginTop: '12px',
      marginBottom: '12px',
      ...theme.palette.common.ellipsis(2),
      [theme.breakpoints.down('md')]: {
        lineHeight: '28px',
        marginBottom: '4px',
      },
      '& p': {
        ...theme.palette.common.ellipsis(1),
        [theme.breakpoints.down('md')]: {
          ...theme.palette.common.ellipsis(2),
        },
      },
    },

    '& p': {
      fontWeight: 700,
    },
  },
  eventCarouselSubHeading: {
    height: '90%',
    ...theme.mixins.borderRight(`1px solid ${theme.mixins.Grey(200)}`),
    backgroundColor: theme.mixins.White(),
    ...theme.mixins.marginRight(pxToRem(14)),
    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.marginRight(0),
    },
  },

  linkRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    borderBottom: ({ umbrellaECount }) =>
      umbrellaECount && `4px solid ${theme.mixins.DarkTurquoise()}`,
    '& .imageWrap': {
      '& img': {
        height: '100%',
        display: 'inline-block',
      },
    },
    '& [class^="MuiChip-root"]': {
      height: '24px',
    },
    '&:hover': {
      '& h3': {
        color: theme.palette.common.SeaPort,
      },
      '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
      '& .icon img': {
        transform: 'none',
      },
    },
  },
  categoryOnImageWrapper: {
    display: 'flex',
    position: 'absolute',
    top: theme.spacing(2),
    ...theme.mixins.left(theme.spacing(3)),
  },
  categoryOnImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${pxToRem(4)} ${pxToRem(10)}`,
    borderRadius: borderRadius.b3,
    color: theme.mixins.Black(),
    backgroundColor: theme.mixins.White(),
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: 0.5,
  },
  umbrellaEventCountWrapper: {
    position: 'absolute',
    bottom: theme.spacing(3),
    left: theme.spacing(3),
  },
  umbrellaEventCount: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.mixins.SeaGreen(),
    height: pxToRem(65),
    width: pxToRem(65),
    '& p': {
      color: theme.mixins.milkWhite(),
    },
    '& span': {
      color: theme.mixins.White(),
    },
  },

}));

export default useEventStyles;